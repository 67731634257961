import React, { useState, useEffect } from 'react';

import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import PicturesLoader from './PicturesLoader';

interface Props {
  images: IGatsbyImageData[];
  currentImageIndex: number;
  onReady: Function;
}

const useStyles = createUseStyles({
  root: {
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    zIndex: 4,
    opacity: 0,
    transition: 'opacity 1s',
  },
  imageContainer: {
    position: 'relative',
    borderRadius: 24,
    overflow: 'hidden',
    zIndex: 2,
  },
  imageContainerAfterFirst: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },

  isLoading: {
    '& $loader': {
      opacity: 1,
    },
  },
});

function PicturesContainer({ images, currentImageIndex, onReady }: Props) {
  const classes = useStyles();
  const [loadedImages, setLoadedImages] = useState<number[]>([]);
  const isFullyLoaded = loadedImages.length === images.length;
  const loadingPercentage = (1 / images.length) * loadedImages.length;

  const handleStartLoad = (props: { wasCached?: boolean }, i: number) => {
    if (props?.wasCached) {
      setLoadedImages((previousLoadedImages) => [...previousLoadedImages, i]);
    }
  };
  const handleLoad = (n: number) => {
    setLoadedImages((previousLoadedImages) => [...previousLoadedImages, n]);
  };

  useEffect(() => {
    if (loadedImages.length === images.length) {
      onReady();
    }
  }, [loadedImages, images]);

  return (
    <div className={cx(classes.root, { [classes.isLoading]: !isFullyLoaded })}>
      <div className={classes.loader}>
        <PicturesLoader percentage={loadingPercentage} />
      </div>
      {images.map((imageData, n) => {
        const image = getImage(imageData);

        if (!image) {
          return null;
        }

        const shouldDisplay =
          (n === 0 && !isFullyLoaded) ||
          (n === currentImageIndex && isFullyLoaded);

        return (
          <div
            key={`image${n}`}
            className={cx(classes.imageContainer, {
              [classes.imageContainerAfterFirst]: n > 0,
            })}
            style={{
              visibility: shouldDisplay ? 'visible' : 'hidden',
            }}
          >
            <GatsbyImage
              image={image}
              alt=""
              onLoad={() => handleLoad(n)}
              onStartLoad={(props) => handleStartLoad(props, n)}
            />
          </div>
        );
      })}
    </div>
  );
}

export default PicturesContainer;
