/* eslint-disable react/forbid-prop-types */
import React, { useState, useLayoutEffect } from 'react';
import useMedia from 'use-media';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import ReactSlider from 'react-slider';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

import Seo from '../components/SEO';
import breakpoints from '../services/breakpoints';
import Layout from '../components/Layout';
import ZoomPicture from '../components/ZoomPicture/ZoomPicture';
import PicturesContainer from '../components/PicturesContainer';

interface Props {
  pageContext: {
    painting: {
      ogImage: IGatsbyImageData;
      thumb: IGatsbyImageData;
      images: IGatsbyImageData[];
      fullImages: IGatsbyImageData[];
      meta: {
        title: string;
        mainColor: string;
      };
    };
  };
}

function getImageIndexFromPercentage(percentage: number, totalImages: number) {
  return Math.round(percentage / (100 / (totalImages - 1)));
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },

  icon: {
    width: 24,
    fill: 'rgba(255, 255, 255, 0.5)',
  },

  backlink: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    color: 'rgba(255, 255, 255, 0.5)',
    fontWeight: 500,
    textDecoration: 'none',
    marginTop: '0.8rem',
    '&> svg': {
      verticalAlign: 'middle',
    },
    [breakpoints.m]: {
      marginTop: 0,
    },
  },

  titlecss: {
    fontWeight: 600,
    fontSize: '2rem',
    margin: [0, 0, 0, '2rem'],
    [breakpoints.m]: {
      margin: ['3rem', 0, 0, 0],
    },
  },

  contentHeader: {
    display: 'flex',
    marginBottom: '2rem',
    [breakpoints.m]: {
      display: 'block',
    },
  },

  slider: {
    marginTop: '1.5rem',
    opacity: 1,
    transition: 'opacity 1s',
  },

  sliderThumb: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    padding: [0, 5],
    height: 20,
    background: 'white',
    borderRadius: 12,
    border: ({ bgColor }: { bgColor: string }) => `4px solid ${bgColor}`,
    color: 'black',
  },

  sliderTrack: {
    background: 'rgba(255, 255, 255, .2)',
    height: 20,
    top: 4,
    borderRadius: 8,
  },

  isLoading: {
    '& $slider': {
      opacity: 0,
    },
  },
});

function Painting({ pageContext }: Props) {
  const {
    painting: { thumb, images, meta, fullImages, ogImage },
  } = pageContext;

  const classes = useStyles({ bgColor: meta.mainColor });
  const canHover = useMedia('(hover: hover) and (pointer: fine)');

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [currentZoomImageIndex, setCurrentZoomImageIndex] = useState(0);
  const thumbnailImage = getImage(thumb) as IGatsbyImageData;
  const ogImageImage = getImage(ogImage) as IGatsbyImageData;
  const [imagesReady, setImagesReady] = useState(false);
  const [displayZoom, setDisplayZoom] = useState(false);
  const canZoom = canHover && !isSliding;

  useLayoutEffect(() => {
    document.body.style.backgroundColor = meta.mainColor;
  }, [meta.mainColor]);

  return (
    <Layout thumb={<GatsbyImage image={thumbnailImage} alt="" />}>
      <Seo title={meta.title} image={ogImageImage?.images?.fallback?.src} />
      <div className={classes.contentHeader}>
        <Link className={classes.backlink} to="/">
          <svg
            className={classes.icon}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            data-testid="ArrowBackIcon"
          >
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
          </svg>
          back to list
        </Link>
        <h2 className={classes.titlecss}>{meta.title}</h2>
      </div>

      <div
        className={cx(classes.container, {
          [classes.isLoading]: !imagesReady,
        })}
      >
        <div
          onMouseEnter={() => setDisplayZoom(true)}
          onMouseLeave={() => setDisplayZoom(false)}
        >
          {canZoom && (
            <ZoomPicture
              visible={displayZoom && imagesReady}
              fullImage={fullImages[currentZoomImageIndex]}
            />
          )}

          <PicturesContainer
            images={images}
            currentImageIndex={currentImageIndex}
            onReady={() => setImagesReady(true)}
          />
        </div>

        <ReactSlider
          ariaLabel="Image selector"
          className={classes.slider}
          thumbClassName={classes.sliderThumb}
          trackClassName={classes.sliderTrack}
          onBeforeChange={() => {
            setIsSliding(true);
          }}
          onAfterChange={(value) => {
            setIsSliding(false);
            setCurrentZoomImageIndex(
              getImageIndexFromPercentage(value, images.length)
            );
          }}
          onChange={(newValue) => {
            setCurrentImageIndex(
              getImageIndexFromPercentage(newValue, images.length)
            );
          }}
          renderThumb={(props, state) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...props}>
              image{' '}
              {Math.round(state.valueNow / (100 / (images.length - 1))) + 1}
            </div>
          )}
        />
      </div>
    </Layout>
  );
}

export default Painting;
