import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  percentage: number;
}

const useStyles = createUseStyles({
  wrapper: {
    strokeDashoffset: 0,
    stroke: 'rgba(0, 0, 0, 0.6)',
    strokeWidth: '1.5em',
  },
  bar: {
    stroke: 'rgba(255, 255, 255, 0.9)',
    strokeWidth: '1em',
    transition: 'strokeDashoffset 1s linear',
  },
});

function PicturesLoader({ percentage }: Props) {
  const classes = useStyles();
  const radius = 40;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - ((percentage * 100) / 100) * circumference;

  return (
    <svg
      width="120"
      height="120"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={classes.wrapper}
        r={radius}
        cx="60"
        cy="60"
        fill="transparent"
      />
      <circle
        className={classes.bar}
        r={radius}
        cx="60"
        cy="60"
        fill="transparent"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
    </svg>
  );
}

export default PicturesLoader;
