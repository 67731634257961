import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import cx from 'classnames';

import Loader from './Loader';

interface Props {
  fullImage: IGatsbyImageData;
  visible?: boolean;
}

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    zIndex: 5,
    width: '100%',
    height: '100%',
    opacity: 0,
  },
  cursor: {
    width: 32,
    height: 32,
    position: 'absolute',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    pointerEvents: 'none',
    borderRadius: 16,
    boxShadow: '0 0 10px 5px rgba(0,0,0,0.28);',
  },
  loadingContainer: {
    zIndex: 1,
    position: 'absolute',
    fontSize: '1rem',
    textAlign: 'center',
    width: '25%',
    margin: 'auto auto',
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 8,
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    '& svg': {
      width: 32,
      verticalAlign: 'middle',
    },
    display: 'none',
  },
  zoomContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  zoom: {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: 24,
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    pointerEvents: 'none',
  },
  isVisible: {
    opacity: 1,
    cursor: 'crosshair',
  },
  isLoading: {
    '& $loadingContainer': {
      display: 'block',
    },
    '& $zoomContainer': {
      display: 'none',
    },
  },
});

function ZoomPicture({ fullImage, visible = false }: Props) {
  const classes = useStyles();
  const rootElementRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState([0, 0]);
  const [mousePos, setMousePos] = useState([0, 0]);
  const [isLoaded, setIsLoaded] = useState(false);

  const imageRatio = fullImage.width / fullImage.height;
  const imageWidth = dimensions[0];
  const imageHeight = dimensions[1];
  const zoomRatio = fullImage.width / imageWidth;
  const cursorWidth = imageWidth / zoomRatio;
  const cursorHeight = imageHeight / zoomRatio;
  const halfCursorWidth = cursorWidth / 2;
  const halfCursorHeight = cursorHeight / 2;

  const offsetX = -(
    (mousePos[0] - halfCursorWidth) *
    (fullImage.width / imageWidth)
  );
  const offsetY = -(
    (mousePos[1] - halfCursorHeight) *
    (fullImage.height / imageHeight)
  );

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    let posX = e.clientX - rect.left;
    let posY = e.clientY - rect.top;

    if (posX > imageWidth - halfCursorWidth - 1) {
      posX = imageWidth - halfCursorWidth - 1;
    } else if (posX < halfCursorWidth) {
      posX = halfCursorWidth;
    }

    if (posY >= imageHeight - halfCursorHeight - 1) {
      posY = imageHeight - halfCursorHeight - 1;
    } else if (posY < halfCursorHeight) {
      posY = halfCursorHeight;
    }
    setMousePos([posX, posY]);
  };

  const updateDim = useCallback(() => {
    if (!rootElementRef.current) {
      return;
    }
    const { width }: { width: number } =
      rootElementRef.current.getBoundingClientRect();
    setDimensions([width, width / imageRatio]);
  }, []);

  useEffect(() => {
    updateDim();
    setIsLoaded(false);
  }, [fullImage.images.fallback?.src, updateDim]);

  useEffect(() => {
    const handleResize = () => {
      updateDim();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={rootElementRef}
      onMouseMove={handleMouseMove}
      className={cx(classes.root, {
        [classes.isVisible]: visible,
        [classes.isLoading]: !isLoaded,
      })}
    >
      <div className={classes.loadingContainer}>
        <Loader /> Loading zoom
      </div>
      <div className={classes.zoomContainer}>
        <div className={classes.zoom}>
          <img
            style={{
              left: `${offsetX}px`,
              top: `${offsetY}px`,
            }}
            className={classes.image}
            src={fullImage.images.fallback?.src}
            width={fullImage.width}
            height={fullImage.height}
            onLoad={() => setIsLoaded(true)}
            alt=""
          />
        </div>
        <div
          className={classes.cursor}
          style={{
            left: `${mousePos[0] - cursorWidth / 2}px`,
            top: `${mousePos[1] - cursorHeight / 2}px`,
            width: `${cursorWidth}px`,
            height: `${cursorHeight}px`,
          }}
        />
      </div>
    </div>
  );
}

export default ZoomPicture;
